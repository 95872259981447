@import "src/styles/variables";

.ptf-intern-users-main {
  height: 100%;
  overflow: hidden;
  .users-list {
    height: 100%;
    overflow: auto;
  }
  .user-item {
    display: flex;
    justify-content: space-between;
  }
}
