@import "src/styles/variables";

.ptf-intern-guest-list {
  list-style: none;
  padding: 0;
  .ant-collapse-header {
    display: flex;
    align-items: center;
    padding: 4px 12px !important;
    .ant-collapse-extra {
      margin-left: auto;
    }
  }
}
.ptf-intern-guest-reminders-menu {
  border: none;
  .ant-menu-item {
    height: auto !important;
    line-height: 30px;
    margin: 0 !important;
    padding: 5px 10px;
  }
}

.ptf-intern-guest-reminders-popover {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
}

.ptf-intern-invite-status-menu {
  max-width: 160px;
  .info-item {
    font-size: 12px;
    line-height: 14px;
    white-space: pre-wrap;
  }
}
