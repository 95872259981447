@import "src/styles/variables";

.ptf-intern-events-list {
  list-style: none;
  margin: 0;
  padding: 10px 0;
  .host-name {
    font-size: 14px;
    color: $ptf-blue;
    text-decoration: underline double;
  }
  .event-item {
    padding: 2px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    .ant-checkbox-wrapper,
    span:not(:last-child) {
      margin-right: 3px;
    }
  }
  .date {
    color: fade-out($black, 0.5);
  }
  .date,
  .guest-count {
    font-size: 12px;
  }
  .event-title {
    text-transform: capitalize;
  }
  .event-item.active {
    background-color: fade-out(#1890ff, 0.5);
  }
}
