@import "src/styles/variables";

.ptf-intern-messages-main {
  height: 100%;
  overflow: hidden;
  .numbers-section {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .search {
        flex: 0 0 auto;
      }
    }
  }
  .search-input {
    border-radius: 0;
    border-top: none;
    border-left: none;
  }
  .numbers-list {
    flex: 1 1 100%;
    overflow: auto;
    border-right: 1px solid $border-color;
    .tag {
      border-radius: $border-radius-base;
      padding: 2px 3px;
      margin-left: 3px;
    }
    .host-tag {
      background: lightblue;
    }
    .guest-tag {
      background: lightgreen;
    }
  }
  .number-item {
    display: flex;
    justify-content: space-between;
    .number {
      font-size: 12px;
    }
  }
  .message-thread-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    .ant-tabs-tab:first-child {
      margin-left: 5px;
    }
    .ant-tabs-tab {
      border-top: none;
      margin-right: 5px !important;
    }
    .ant-tabs-nav {
      flex: 0 0 auto;
      margin-bottom: 0;
    }
    .ant-tabs-content-holder {
      flex: 1 1 100%;
      overflow: hidden;
      background: white;
      .ant-tabs-content {
        height: 100%;
      }
    }
    .ant-tabs-content-holder {
      overflow: auto;
    }
    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  .ptf-intern-message-thread {
    flex: 1 1 100%;
  }
  .ptf-intern-message-input {
    flex: 0 0 auto;
    border-top: 1px solid $border-color;
    background: $background-color;
  }
}
