@import "src/styles/variables";

.ptf-intern-event-detail {
  padding: 10px 20px;
  overflow: auto;
  .stats-section {
    .stats {
      display: flex;
    }
    .ant-statistic:not(:last-child) {
      margin-right: 10px;
    }
  }
  .guests-section {
    margin-top: 20px;
    .guest-list {
      border-radius: $border-radius-base;
      // max-height: 800px;
      // overflow: auto;
    }
  }
}
