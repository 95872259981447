@import "src/styles/variables";

$bg-color: white; // #f0f2f5;
.ptf-intern-message-thread {
  min-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
  &.empty,
  &.loading {
    align-items: center;
    justify-content: center;
  }
  .message {
    margin-top: 20px;
    width: auto;
    max-width: 400px;
    word-wrap: break-word;
    margin-bottom: 12px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 14px;
    line-height: 18px;
    a {
      color: white;
      text-decoration: underline;
    }
  }
  .message .date {
    position: absolute;
    top: -20px;
    color: rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    font-size: 12px;
    &.last-message {
      font-weight: bold;
    }
  }
  .message .image {
    max-height: 500px;
    max-width: 380px;
    object-fit: contain;
  }
  .message::before,
  .message::after {
    content: "";
    position: absolute;
    bottom: -2px;
    height: 20px;
  }
  .message::after {
    background: $bg-color;
    width: 26px;
  }

  .message.outbound {
    color: white;
    background: #0b93f6;
    align-self: flex-end;
    .date {
      right: 10px;
    }
  }
  .message.outbound::before {
    right: -7px;
    border-right: 20px solid #0b93f6;
    border-bottom-left-radius: 16px 14px;
    transform: translate(0, -2px);
  }
  .message.outbound::after {
    right: -56px;
    border-bottom-left-radius: 10px;
    transform: translate(-30px, -2px);
  }

  .message.inbound {
    background: #e5e5ea;
    color: black;
    .date {
      left: 10px;
    }
  }
  .message.inbound::before {
    left: -7px;
    border-left: 20px solid #e5e5ea;
    border-bottom-right-radius: 16px 14px;
    transform: translate(0, -2px);
  }
  .message.inbound::after {
    left: 4px;
    border-bottom-right-radius: 10px;
    transform: translate(-30px, -2px);
  }
}
