@import "src/styles/variables";

.ptf-image-search {
  .results-container {
    display: flex;
    flex-wrap: wrap;
  }
  .image-result {
    width: 300px;
    margin-right: 20px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
