@import "src/styles/variables";

.ptf-intern-message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  .message-textarea {
    margin-right: 10px;
  }
  .message-textarea::-webkit-resizer {
    color: transparent;
  }
}
