@import "src/styles/variables";

.ptf-intern-templates-main {
  .preview-picker-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 20px 0;
    .event-picker {
      flex: 1 1 30vw;
      width: 30vw;
      min-width: 200px;
      max-width: 300px;
    }
  }
  .templates-grid {
    display: flex;
    flex-wrap: wrap;
    .template-card {
      min-width: 200px;
      width: 1 1 25vw;
      max-width: 30vw;
      margin: 20px;
      .section:last-child .ant-divider {
        display: none;
      }
    }
  }
}
