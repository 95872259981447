@import "~normalize.css";
@import "~antd/dist/antd";
@import "./styles/variables";
@import "./styles/base";

#app {
  height: 100%;
  &.loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#content {
  height: 100%;
  overflow: auto;
}
