@import "src/styles/variables";

.ptf-intern-events-main {
  display: flex;
  height: 100%;
  align-items: stretch;
  .pane {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .pane-header {
      flex: 0 0 auto;
    }
    .pane-content {
      flex: 1 1 100%;
    }
  }
  .pane:not(:last-child) {
    border-right: 1px solid $border-color;
  }
  .pane-header {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid $border-color;
    padding: 5px 10px;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 43px;
    .simulated-date-picker {
      min-width: 165px;
    }
  }
  .filter-pane {
    flex: 0 0 300px;
    .filter-form {
      padding: 20px;
    }
  }
  .sort-picker {
    min-width: 130px;
  }
  .results-pane {
    max-width: 600px;
  }
  .results-pane,
  .detail-pane {
    flex: 1 1 50%;
  }
  .detail-pane {
    .ant-breadcrumb {
      font-size: 16px;
      text-transform: capitalize;
    }
  }
  .events-list {
    overflow: auto;
  }
  .pane-content.ant-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
