// Only define variables in this file!

// Theme variables
// TODO: Properly convert to less with functions so we can do things like lighten()

// Shared with antd
$font-family: WorkSans-Regular;

$primary-color: #192671; // primary color for all components
// $link-color: #1890ff; // link color
$success-color: #192671; // success state color
// $warning-color: #faad14; // warning state color
$error-color: #ff4d4f; // error state color
$heading-color: #192671;
$text-color: rgba(0, 0, 0, 0.65); // major text color
// $text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 3px; // major border radius
// $border-color-base: #d9d9d9; // major border color
// $box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
$font-size-base: 14px;
$font-size-lg: 16px;
$font-size-sm: 12px;
$form-item-label-font-size: 16px;

$white: #fff;
$black: #001529;

$input-bg: rgba(25, 38, 113, 0.1);
$input-icon-color: #192671;
$input-border-color: transparent;
$input-placeholder-color: rgba(0, 0, 0, 0.15);

// Datetime
$picker-bg: rgba(25, 38, 113, 0.1);

$link-decoration: underline;

// fade-out($error-color, 0.9);
$form-error-input-bg: rgba(255, 77, 79, 0.1);

// Drop down menu
// $menu-item-active-bg: rgba(0, 0, 0, 0.1);

// Collpase
$collapse-header-bg: transparent;
$collapse-content-bg: transparent;

// Partiful-only
$ptf-blue: #192671;
$ptf-pink: #e3ac94;
$ptf-yellow: #ffce55;
$solid-light-blue: #e8e8ef;
$solid-light-gray: #f0f0f0;
$mobile-width: 680px;

$border-color: #d9d9d9;
$background-color: #f0f2f5;

$blue: #1890ff;
